var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ls-category-edit"},[_c('div',{staticClass:"ls-card category-edit__header"},[_c('el-page-header',{attrs:{"content":_vm.id ? '编辑分类' : '新增分类'},on:{"back":function($event){return _vm.$router.go(-1)}}})],1),_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"120px","size":"small","rules":_vm.rules}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"ls-card category-edit__base m-t-16"},[_c('div',{staticClass:"nr weight-500 m-b-20"},[_vm._v("基础信息")]),_c('el-form-item',{attrs:{"label":"分类名称","prop":"name","required":""}},[_c('el-input',{staticClass:"ls-input",attrs:{"maxlength":"8","show-word-limit":"","placeholder":"请输入分类名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"父级分类","required":""}},[_c('el-radio-group',{staticClass:"m-r-16",model:{value:(_vm.hasPid),callback:function ($$v) {_vm.hasPid=$$v},expression:"hasPid"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("无父级分类")]),_c('el-radio',{attrs:{"label":1}},[_vm._v("有父级分类")])],1),_c('el-alert',{staticClass:"ls-tips",attrs:{"title":"选择无上级分类，则表明此分类为一级分类；选择有上级分类，则表明此分类为选中分类的子分类","type":"warning","closable":false}})],1),(_vm.hasPid == 1)?_c('el-form-item',{attrs:{"label":"","required":""}},[_c('el-cascader',{attrs:{"options":_vm.categoryList,"props":{
                        checkStrictly: true,
                        value: 'id',
                        label: 'name',
                        children: 'sons',
                        emitPath: false
                    },"clearable":""},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return (data.level <= 2)?[_c('span',[_vm._v(_vm._s(data.name))]),(!node.isLeaf)?_c('span',[_vm._v(" ("+_vm._s(data.sons.length)+") ")]):_vm._e()]:undefined}}],null,true),model:{value:(_vm.form.pid),callback:function ($$v) {_vm.$set(_vm.form, "pid", $$v)},expression:"form.pid"}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":"分类图标","prop":"image"}},[_c('material-select',{attrs:{"limit":1},model:{value:(_vm.form.image),callback:function ($$v) {_vm.$set(_vm.form, "image", $$v)},expression:"form.image"}}),_c('div',{staticClass:"muted xs"},[_vm._v("建议尺寸：宽200像素*高200像素的jpg，jpeg，png图片")])],1),_c('el-form-item',{attrs:{"label":"排序"}},[_c('el-input',{staticStyle:{"width":"220px"},model:{value:(_vm.form.sort),callback:function ($$v) {_vm.$set(_vm.form, "sort", $$v)},expression:"form.sort"}}),_c('div',{staticClass:"muted xs"},[_vm._v("排序值必须为整数；数值越小，越靠前")])],1),_c('el-form-item',{attrs:{"label":"是否显示"}},[_c('el-switch',{attrs:{"active-value":1,"inactive-value":0},model:{value:(_vm.form.is_show),callback:function ($$v) {_vm.$set(_vm.form, "is_show", $$v)},expression:"form.is_show"}})],1)],1)]),_c('div',{staticClass:"category-edit__footer bg-white ls-fixed-footer"},[_c('div',{staticClass:"btns row-center flex",staticStyle:{"height":"100%"}},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("取消")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.handleSave}},[_vm._v("保存")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }